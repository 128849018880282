import {FC} from "react"

import Layout from "../components/layout"

const IndexPage:FC = () => {

return (
  <Layout>
  </Layout>
)
}
export default IndexPage
